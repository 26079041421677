<template>
  <exception-page type="500" />
</template>

<script>
import ExceptionPage from "./ExceptionPage";

export default {
  name: "e500",
  components: {
    ExceptionPage,
  },
};
</script>

<style scoped></style>
